<template>
  <div>
    <file-field
      ref="file"
      :value="videoName"
      :label="label"
      :accept="accept"
      :auto-upload="autoUpload"
      :upload="upload"
      v-bind="$attrs"
    >
      <template v-if="btnText" v-slot:append>

        <btn
          class="mr-n2"
          color="grey"
          @click="$refs.file && $refs.file.click($event)"
          small
        >
          {{ btnText }}
        </btn>

      </template>
      <template v-slot:append-outer>

        <btn
          :disabled="!value || uploading"
          :dark="!uploading && !!value"
          @click.stop="showVideo = true"
          class="ml-1 mt-1 px-0"
          min-width="32"
          height="26"
        >
          <v-icon small>
            {{ iconPlay }}
          </v-icon>
        </btn>

      </template>
    </file-field>

    <v-dialog
      v-model="showVideo"
      :max-width="dialogMaxWidth"
    >
      <v-card class="pa-1">

        <btn
          class="d-block ml-auto my-1 mr-1"
          icon="mdi-close"
          @click="showVideo = false"
          small
        />

        <v-responsive
          ref="container"
          class="black relative"
          :aspect-ratio="aspectRatio"
        >

          <video
            ref="video"
            class="absolute layer"
            @error="error = true"
            :src="videoUrl"
            :width="videoWidth"
            :height="videoHeight"
            controls
          >
            {{ invalidTag }}
          </video>

          <div
            v-if="error"
            class="d-flex align-center justify-center absolute layer"
          >
            <v-alert type="error">
              {{ invalidVideo }}
            </v-alert>
          </div>

        </v-responsive>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FileField from '../FileField';
import { FILES_BASE_URL } from '@/utils/constants';

export default {
  components: { FileField },
  props: {
    value: [ String, Object ],
    label: String,
    btnText: String,
    autoUpload: {
      type: Boolean,
      default: true
    },
    accept: {
      type: String,
      default: 'video/mp4,video/x-m4v,video/*'
    },
    iconPlay: {
      type: String,
      default: 'mdi-play'
    },
    dialogMaxWidth: {
      type: [ Number, String ],
      default: 1024
    },
    aspectRatio: {
      type: [ Number, String ],
      default: 16/9
    },
    baseUrl: {
      type: String,
      default: FILES_BASE_URL
    },
    invalidTagMessage: String,
    invalidVideoMessage: String
  },
  data: () => ({
    showVideo: false,
    uploading: false,
    videoWidth: 0,
    videoHeight: 0,
    error: false
  }),
  computed: {
    videoName() {
      if ( ! this.value ) return undefined;
      const url = String( this.value.url || this.value || '' );
      return url.slice( url.lastIndexOf('/') + 1 );
    },
    videoUrl() {
      return [ this.baseUrl, this.videoName ].filter( Boolean ).join('/');
    },
    videoType() {
      return `video/${ this.videoUrl.slice( this.videoUrl.lastIndexOf('.') + 1 )}`;
    },
    invalidTag() {
      return this.invalidTagMessage || this.$t('alerts.invalidTagVideo');
    },
    invalidVideo() {
      return this.invalidVideoMessage || this.$t('alerts.invalidVideo');
    }
  },
  watch: {
    showVideo( value ) {
      this.resize( value );
      !value && this.stopVideo();
    },
    videoUrl() {
      this.error = false;
    }
  },
  methods: {
    resize( value ) {
      if ( value ) {
        window.addEventListener( 'resize', this.onResize );
        setTimeout( this.onResize );
      } else {
        window.removeEventListener( 'resize', this.onResize );
      }
    },
    onResize() {
      const { container } = this.$refs;
      if ( container ) {
        this.videoWidth = container.$el.clientWidth;
        this.videoHeight = container.$el.clientHeight;
      }
    },
    upload( formData ) {

      if ( this.uploading ) return;
      this.uploading = true;

      return this.$store.dispatch( 'api/upload/file', {
        silence: true,
        formData
      })
      .then( file => {
        this.$emit( 'input', file );
        return file.url.slice( file.url.lastIndexOf('/') + 1 );
      })
      .catch(() => {
        this.$store.dispatch( 'app/error', {
          message: this.$t('alerts.uploadError')
        })
      })
      .finally(() => {
        this.uploading = false;
      });
    },
    stopVideo() {
      const { video } = this.$refs;
      if ( video ) {
        video.pause();
        video.currentTime = 0;
      }
    }
  }
}
</script>
