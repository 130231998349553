<template>
  <div :class="classes">

    <label v-if="label" class="caption">
      {{ label }}
    </label>

    <div>
      <v-card
        class="my-2"
        v-for="( card, index ) in internalValue"
        :key="card.id"
        outlined
      >
        <v-row class="ma-0">
          <v-col cols="12" sm="3" class="pa-1">
            <text-field
              v-model="card.name"
              :label="$t('inputs.name.label')"
              :rules="rules.name"
            />
          </v-col>
          <v-col cols="12" sm="3" class="pa-1">
            <text-field
              v-model="card.username"
              :label="$t('inputs.alias.label')"
              :rules="rules.username"
            />
          </v-col>
          <v-col cols="12" sm="3" class="pa-1">
            <text-field
              v-model="card.link"
              :label="$t('inputs.link.label')"
              :rules="rules.link"
            />
          </v-col>
          <v-col cols="12" sm="3" class="d-flex align-center pa-1">

            <number-field
              v-model="card.followers"
              :label="$t('inputs.followers.label')"
              :rules="rules.followers"
              min="0"
            />

            <btn
              class="social-field--btn-close ml-2"
              x-small icon
              @click="internalValue.splice( index, 1 )"
            >
              <v-icon small>mdi-close</v-icon>
            </btn>

          </v-col>
        </v-row>
      </v-card>
    </div>

    <btn class="mt-2" @click="add">
      {{ $t('btn.addSocial') }}
    </btn>

  </div>
</template>

<script>
import TextField from '../TextField';
import NumberField from '../NumberField';
import { deepEqual } from 'vuetify/lib/util/helpers';
import { required, isLink } from '@/utils/rules';

export default {
  components: {
    TextField,
    NumberField
  },
  props: {
    label: String,
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      internalValue: this.value || []
    }
  },
  computed: {
    classes() {
      return {
        'social-field': true,
        'social-field--is-mobile': this.$vuetify.breakpoint.xs
      }
    },
    rules() {
      return {
        name: [
          required( this.$t('inputs.name.rules.required'))
        ],
        username: [
          required( this.$t('inputs.alias.rules.required'))
        ],
        link: [
          required( this.$t('inputs.link.rules.required')),
          isLink( this.$t('inputs.link.rules.isLink'))
        ]
      };
    },
  },
  watch: {
    value( value ) {
      this.internalValue = value || []
    },
    internalValue( value, old ) {
      if ( deepEqual( value, old )) return;
      this.$emit( 'input', value );
    }
  },
  methods: {
    add() {
      this.internalValue.push({
        id: new Date().getTime() + '',
        name: '',
        username: '',
        link: '',
        followers: 0
      });
    }
  }
}
</script>

<style>
.social-field .v-card {
  position: relative;
  padding: 0 12px;
}
.social-field.social-field--is-mobile .v-card {
  padding-top: 24px;
}
.social-field.social-field--is-mobile .social-field--btn-close {
  position: absolute;
  top: 12px;
  right: 12px;
}
</style>
