<template>
  <div>
    <v-alert v-if="!model" type="error" tile>
      {{ $t('users.notFound') }}
    </v-alert>
    <v-alert v-if="!isActive" type="error" tile>
      {{ $t(`users.status.alert${model.currentStatus}`) }}
    </v-alert>
    <v-form
      v-if="model"
      ref="form"
      @submit.prevent="save()"
    >

      <v-toolbar
        elevation="0"
        class="toolbar-user"
        color="transparent"
        height="170"
        tile
      >
        <template v-slot:img>
          <v-img
            height="100%"
            gradient="to top, rgba(0,0,0,.05), rgba(0,0,0,0)"
          />
        </template>

        <image-field
          v-model="model.picture"
          class="mx-2 caption"
          height="140"
          width="140"
          outlined
          rounded
          contain
        />

      </v-toolbar>
      <v-row class="ma-0 py-6">
        <v-col class="py-3" cols="12" md="8">
          <v-row>

            <v-col class="py-0" cols="12" md="6">
              <text-field
                v-model="model.name"
                :label="$t('inputs.name.label')"
                background-color="white"
                autocomplete="given-name"
              />
            </v-col>

            <v-col class="py-0" cols="12" md="6">
              <text-field
                v-model="model.surname"
                :label="$t('inputs.surname.label')"
                background-color="white"
                autocomplete="family-name"
              />
            </v-col>

            <v-col class="py-0" cols="12" md="6">
              <text-field
                autocomplete="email"
                background-color="white"
                :value="model.email"
                :error="userExist"
                :label="$t('inputs.email.label')"
                :rules="rules.email"
                @input="onInput('email',$event)"
              />
            </v-col>

            <v-col class="py-0" cols="12" md="6">
              <text-field
                v-model="model.phone"
                autocomplete="phone"
                background-color="white"
                :label="$t('inputs.phone.label')"
                :rules="rules.phone"
              />
            </v-col>

            <v-col v-if="isAppUser" class="py-0" cols="12" md="6">
              <video-field
                class="mb-2"
                v-model="model.video"
                :label="$t('inputs.videoUrl.label')"
                :btn-text="$t('btn.file')"
                background-color="white"
              />
            </v-col>

            <v-col
              v-if="!isNew && !isProfile && canEdit"
              class="py-0" cols="12" md="6"
            >
              <select-field
                v-model="model.status"
                background-color="white"
                :label="$t('inputs.status.label')"
                :items="status"
                :rules="rules.status"
              />
            </v-col>

            <v-col v-if="!isAppUser" class="py-0" cols="12" md="6">
              <select-field
                v-model="model.role"
                background-color="white"
                :label="$t('inputs.role.label')"
                :items="roles"
                :rules="rules.role"
                :readonly="!canEdit || isProfile"
              />
            </v-col>

          </v-row>
        </v-col>
        <v-col
          v-if="isProfile || isNew"
          class="py-0" cols="12" md="4"
        >

          <password-field
            :value="model.password"
            :label="$t('inputs.password.label')"
            :rules="rules.password"
            name="MyPassword"
            autocomplete="new-password"
            background-color="white"
            @input="model = { ...model, password: $event }"
            show-password
          />

          <password-field
            v-model="password2"
            :label="$t('inputs.repeatPassword.label')"
            :rules="rules.password2"
            name="MyPassword2"
            autocomplete="repeat-password"
            background-color="white"
            show-password
          />

        </v-col>
        <v-col v-if="isAppUser" class="py-0" cols="12">

          <social-field
            v-model="model.socials"
            :label="$t('inputs.socials.label')"
          />

        </v-col>
      </v-row>

      <v-footer
        color="background"
        height="56"
        app inset
      >

        <!--<btn
          v-if="!isNew && !isProfile && canEdit"
          @click="removing = true"
          text
        >
          {{ $t('users.btn.remove') }}
        </btn>-->

        <v-spacer/>

        <btn color="black" @click="save()" dark>
          {{ $t('users.btn.save') }}
        </btn>

      </v-footer>

      <template v-if="!isNew">

        <m-dialog
          v-model="changingEmail"
          :title="$t('users.changeEmailTitle')"
          max-width="480"
          @click:accept="onChangeEmail"
          persistent
          actions
        >
          <p v-html="$t('users.changeEmailBody')"/>
        </m-dialog>

        <!--<remove-dialog
          v-model="removing"
          :title="$t('users.removeOneTitle')"
          :items="[model]"
          @removed="onRemove"
          api="users"
        />-->

      </template>

    </v-form>
  </div>
</template>

<script>
import TextField from '@/components/form/TextField';
import SelectField from '@/components/form/SelectField';
//import RemoveDialog from '@/components/RemoveDialog';
import PasswordField from '@/components/form/PasswordField';
import ImageField from '@/components/form/ImageField';
import SocialField from '@/components/form/SocialField';
import VideoField from '@/components/form/VideoField';
import { USER_ROLES, USER_STATUS, ICONS } from '@/utils/constants';
import { required, minLength, minMatch, sameAs, isEmail } from '@/utils/rules';
import { mapState, mapActions } from 'vuex';

const USER_DEFAULT = {
  id: -1,
  name: '',
  surname: '',
  email: '',
  phone: '',
  password: null,
  picUrl: null,
  videoUrl: null,
  role: null,
  status: USER_STATUS.PENDING
};

function computeUser( data = {} ) {

  data = { ...USER_DEFAULT, ...data };

  if ( ! data.socials ) data.socials = [];
  else data.socials = data.socials.map( s => s.social );

  if ( data.picUrl )
    data.picture = { id: data.picId, url: data.picUrl };

  if ( data.videoUrl )
    data.video = { id: data.videoId, url: data.videoUrl };

  data.currentEmail = data.email;
  data.currentStatus = data.status;
  delete data.password;

  return data;
}

export default {
  name: 'User',
  components: {
    TextField,
    SelectField,
    PasswordField,
    ImageField,
    SocialField,
    VideoField,
    //RemoveDialog
  },
  auth: true,
  head: vm => ({
    title: vm.$route.path.includes('profile')
      ? vm.$i18n.t('menu.profile')
      : vm.$i18n.t('users.title')
  }),
  layout: 'admin',
  layoutProps: ({ route, i18n, lp }, fetched ) => {

    const isAppUser = route.path.includes('app');
    const isProfile = ! route.params.user;
    const { model } = fetched;

    let title = isProfile
      ? i18n.t('menu.profile')
      : model
        ? model.id > 0
          ? [ model.name, model.surname ].filter( Boolean ).join(' ')
          : i18n.t('users.btn.new')
        : '';

    return {
      title,
      icon: isProfile ? ICONS.profile : null,
      breadcrumbs: !isProfile && [
        isAppUser ? {
          text: i18n.t( 'appUsers.title'),
          to: lp('/app/users'),
          iconLeft: ICONS.appUsers,
          exact: true
        } : {
          text: i18n.t( 'users.title'),
          to: lp('/users'),
          iconLeft: ICONS.users,
          exact: true
        }
      ]
    }
  },
  fetchData({ store, route, redirect }) {

    const { user } = route.params;
    const { isAdmin, isSuperAdmin } = store.state.auth;
    const isProfile = !user;

    if ( ! isProfile && ! isAdmin && ! isSuperAdmin ) {
      return redirect('/');
    }

    const currentUser = store.state.auth.user;
    if ( currentUser.id === Number( user )) {
      return redirect('/profile');
    }

    if ( isProfile ) {
      return { model: computeUser( currentUser ) };
    }

    if ( user === 'new' ) {
      return { model: computeUser() };
    }

    return store.dispatch( 'api/users/get', {
      id: Number( user )
    }).then( model => {
      if ( route.path.includes('app')) {
        if ( model.role !== USER_ROLES.USER ) {
          return redirect(`/users/${model.id}`);
        }
      } else if ( model.role === USER_ROLES.USER ) {
        return redirect(`/app/users/${model.id}`);
      }
      return { model: computeUser( model ) };
    });
  },
  data: () => ({
    model: null,
    removing: false,
    changingEmail: false,
    passLoading: false,
    password2: undefined,
    userExist: false
  }),
  computed: {
    ...mapState( 'auth', [ 'user', 'isAdmin', 'isSuperAdmin' ]),
    roles() {
      return Object.values( USER_ROLES ).map( v => ({
        value: Number(v),
        text: this.$t(`users.roles.${v}`)
      }));
    },
    status() {
      return Object.values( USER_STATUS ).map( v => ({
        value: Number(v),
        text: this.$t(`users.status.${v}`)
      }));
    },
    rules() {
      return {
        email: [
          required( this.$t('inputs.email.rules.required')),
          isEmail( this.$t('inputs.email.rules.isEmail'))
        ],
        role: [
          required( this.$t('inputs.role.rules.required'))
        ],
        password: [
          minMatch(/[a-z]/g, 1, this.$t('inputs.password.rules.lower',{ n:1 })),
          minMatch(/[A-Z]/g, 1, this.$t('inputs.password.rules.upper',{ n:1 })),
          minMatch(/[0-9]/g, 1, this.$t('inputs.password.rules.number',{ n:1 })),
          minMatch(/[!@#$%^&*)(+=._-]/g, 1, this.$t('inputs.password.rules.symbol',{ n:1 })),
          minLength( 8, this.$t('inputs.password.rules.min',{ n:8 }))
        ],
        password2: [
          sameAs( 'password', this.model, this.$t('inputs.repeatPassword.rules.sameAs'))
        ]
      };
    },
    isNew() {
      return this.$route.params.user === 'new';
    },
    isProfile() {
      const param = this.$route.params.user;
      return ! param || this.user.id === Number( param );
    },
    isAppUser() {
      return this.$route.path.includes('app');
    },
    isActive() {
      if ( ! this.model ) return true;
      return this.isNew || this.model.currentStatus === USER_STATUS.ENABLED;
    },
    canEdit() {
      if ( this.isNew ) return true;
      if ( this.isAdmin ) return this.model.role !== USER_ROLES.SUPERADMIN;
      return this.isSuperAdmin;
    }
  },
  watch: {
    $route() {
      this.$refs.form.resetValidation();
    },
    isProfile( value ) {
      value && ( this.model = computeUser( this.user ));
    }
  },
  methods: {
    ...mapActions( 'app', [ 'alert' ]),
    onInput( key, value ) {
      this.userExist = false;
      this.$set( this.model, key, value );
    },
    sanitize( model ) {
      return {
        ...model,
        role: this.isAppUser ? USER_ROLES.USER : model.role,
        picUrl: undefined,
        picture: undefined,
        videoUrl: undefined,
        videoId: undefined,
        currentEmail: undefined,
        currentStatus: undefined,
        idPicture: ( model.picture && model.picture.id ) || null,
        idVideo: ( model.video && model.video.id ) || null
      };
    },
    saveSocials( model ) {
      return Promise.all(( model.socials || [] )
        .map( social => this.$store.dispatch( 'api/users/addSocial', {
          ...social,
          id: typeof social.id === 'string' ? -1 : social.id
        })));
    },
    save( force ) {
      if ( this.$refs.form.validate()) {

        const isChangedEmail = this.model.email !== this.model.currentEmail;

        if ( ! force && ! this.isNew && isChangedEmail )
          return this.changingEmail = true;

        const model = this.sanitize( this.model );

        return this.saveSocials( model ).then( socials => {
          model.socials = socials.map( s => s.id );
          return this.$store.dispatch( 'api/users/set', model );
        })
        .then( res => {

          this.model.currentEmail = this.model.email;
          this.model.currentStatus = this.model.status;

          if ( this.isNew ) {
            this.$router.replace( this.$lp(`${ this.isAppUser ? '/app' : '' }/users/${res.id}`));
          } else if ( ! this.isProfile ) {
            this.$store.commit( 'app/set', {
              props: {
                ...this.$store.state.app.props,
                title: [ model.name, model.surname ].filter( Boolean ).join(' ')
              }
            })
          }

          if ( this.isProfile ) {
            if ( isChangedEmail ) {
              this.$store.dispatch( 'auth/logout' );
            } else {
              const user = { ...model };
              delete user.password;
              this.$store.commit( 'auth/setUser', user );
            }
          }

          this.alert({
            type: 'success',
            message: this.$t('alerts.save')
          });

        }).catch( err => {
          this.userExist = err.error === -4;
          this.alert({
            type: 'error',
            message: this.$t(`alerts.${ this.userExist ? 'userExist' : 'error' }`)
          });
        });
      }
    },
    onChangeEmail() {
      this.changingEmail = false;
      this.save( true );
    },
    onRemove() {
      if ( this.isProfile ) {
        this.$store.dispatch('auth/logout');
      } else {
        this.$router.replace( this.$lp('/users'));
      }
    },
  },
}
</script>

<style>
@media ( max-width: 960px ) {
  .toolbar-user .image-field {
    margin: 0 auto !important;
  }
}
</style>
